(function () {
  'use strict';

  angular
    .module('events.people')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.people', {
        abstract: true,
        url: '/:eventId/people',
        templateUrl: 'people/people.tpl.html'
      })
    ;
  }
}());
